import axios, { AxiosResponse } from "axios";
import ConfigHelper from "helpers/ConfigHelper";
import Address from "../../components/confirmAddress/Models/address";
import ApiHelper from "../ApiHelper";
import { TrackGAEvent } from "../../components/gaTracking/GATracking";

export default abstract class ApnDataService {
    public static validateAPN(apn: string, county: number): Promise<boolean> {

        var config = ApiHelper.GetConfig();

        const baseUrl = new URL(ConfigHelper.ApiBaseUrl);
        const url = new URL('apn', baseUrl);
        const params = { apn: apn, countyNum: county };
        return axios.post(url.toString(), params, config).then((response: AxiosResponse) => {
            return response.data.exists || false;
        })
            .catch((e) => {
                console.log(e);
                TrackGAEvent("service", "failed", "APN Data service failed to validate APN");
                return false;
            });
    }



    public static addressToAPN(address: Address): Promise<{ apn: string, county: string, city: string }> {
        var config = ApiHelper.GetConfig();
        return axios.get(ConfigHelper.ApiBaseUrl + `address/apnlookup?street=${address.streetAddress}&city=${address.city}&state=${address.state}&zip=${address.zipCode}`, config).then((response: AxiosResponse) => {
            return response.data;
        })
            .catch((e) => {
                console.log(e);
                TrackGAEvent("service", "failed", "APN data service failed to find APN");
                return { apn: "", county: "" };
            })
    }
}