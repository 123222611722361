import axios, { AxiosResponse } from "axios";
import ConfigHelper from "helpers/ConfigHelper";
import ApiHelper from "../ApiHelper";
import { TrackGAEvent } from "../../components/gaTracking/GATracking";
import OrderDetails from "../Models/OrderDetails";

export default class OrderDataService {

    public static SubmitOrder(orderDetails: OrderDetails): Promise<string> {
        orderDetails.customerID = 18;
        let config = ApiHelper.GetConfig(orderDetails);
        return axios.post(ConfigHelper.ApiBaseUrl + "order", null, config).then((response: AxiosResponse) => {
            return response.data.OrderId;
        })
            .catch((e) => {
                console.log(e);
                TrackGAEvent("service", "failed", "order data service failed");
                return null;
            });
    }

    public static ValidatePromoCode = (promoCode: string, reportType: number): Promise<string> => {
        let config = ApiHelper.GetConfig();
        let url = ConfigHelper.ApiBaseUrl + `voucherify/validatevoucher?promocode=${promoCode}&reportType=${reportType}`;
        return axios.get(url, config)
            .then((response: AxiosResponse) => response.data.voucherCode)
            .catch(error => {
                console.log(error);
                TrackGAEvent("service", "failed", "promo code data service failed");
            })
    }

    public static getOrderHistory = (userId: string): Promise<any> => {
        let config = ApiHelper.GetConfig();
        let url = ConfigHelper.ApiBaseUrl + `orderhistory?userId=${userId}`;
        return axios.get(url, config)
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch(error => {
                console.error(error);
                TrackGAEvent("service", "failed", "order history service call failed");
            })
    }


};
